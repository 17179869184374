<script>
    import { Bar, mixins } from 'vue-chartjs';
    export default {
        name: 'BarChart',
        extends: Bar,
        mixins: [mixins.reactiveProp],
        props: ['options'],  
        mounted() {
            this.addPlugin({
                id: 'xAxisLabelPlugin',
                beforeInit: function (chart) {
                    chart.data.labels.forEach(function (label, index, labelsArr) {
                    });
                }
            });
        
            this.renderChart(this.chartData, this.options);
        }    
    }
</script>