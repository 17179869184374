const getColorPalette = function (palette, numberOfColors) {

    //Up to 7 colors, use this palette
    if (numberOfColors < 8) {
        var defaultColors = [
            "#AB1B28",
            "#14338E",
            "#005148",
            "#33691E",
            "#F77B12",
            "#641D09",
            "#252E33",
            "#148DC9"
        ];

        var colors = [];

        for (var index = 0; index < numberOfColors; index++) {
            colors[index] = defaultColors[index];
        }

        return colors;
    }

    /*Gradients
      The keys are percentage and the values are the color in a rgba format.
      You can have as many "color stops" (%) as you like.
      0% and 100% is not optional.*/
    var gradient;
    switch (palette) {
        case 'cool':
            gradient = {
                0: [224, 242, 216, 1],
                20: [220, 237, 200, 1],
                45: [66, 179, 213, 1],
                65: [26, 39, 62, 1],
                100: [0, 0, 0, 1]
            };
            break;
        case 'warm':
            gradient = {
                0: [255, 255, 255, 1],
                20: [254, 235, 101, 1],
                45: [228, 82, 27, 1],
                65: [77, 52, 47, 1],
                100: [0, 0, 0, 1]
            };
            break;
        case 'neon':
            gradient = {
                0: [255, 255, 255, 1],
                20: [255, 236, 179, 1],
                45: [232, 82, 133, 1],
                65: [106, 27, 154, 1],
                100: [0, 0, 0, 1]
            };
            break;
        case 'happy':
            gradient = {
                0: [255, 20, 20, 1],
                2: [230, 15, 0, 1],
                5: [183, 28, 28, 1],
                10: [74, 20, 140, 1],
                15: [49, 27, 146, 1],
                20: [26, 35, 126, 1],
                25: [13, 71, 161, 1],
                30: [1, 87, 155, 1],
                35: [0, 96, 100, 1],
                40: [0, 77, 64, 1],
                45: [27, 94, 32, 1],
                50: [51, 105, 30, 1],
                55: [130, 119, 23, 1],
                60: [245, 127, 23, 1],
                65: [255, 111, 0, 1],
                70: [230, 81, 0, 1],
                75: [191, 54, 12, 1],
                80: [62, 39, 35, 1],
                85: [33, 33, 33, 1],
                90: [38, 50, 56, 1],
                95: [18, 152, 219, 1],
                100: [0, 0, 0, 1]
            };
            break;
    }

    //Get a sorted array of the gradient keys
    var gradientKeys = Object.keys(gradient);
    gradientKeys.sort(function (a, b) {
        return +a - +b;
    });

    //Calculate colors
    var chartColors = [];
    for (var i = 0; i < numberOfColors; i++) {
        var gradientIndex = (i + 1) * (100 / (numberOfColors + 1)); //Find where to get a color from the gradient
        for (var j = 0; j < gradientKeys.length; j++) {
            var gradientKey = gradientKeys[j];
            if (gradientIndex === +gradientKey) { //Exact match with a gradient key - just get that color
                chartColors[i] = 'rgba(' + gradient[gradientKey].toString() + ')';
                break;
            } else if (gradientIndex < +gradientKey) { //It's somewhere between this gradient key and the previous
                var prevKey = gradientKeys[j - 1];
                var gradientPartIndex = (gradientIndex - prevKey) / (gradientKey - prevKey); //Calculate where
                var color = [];
                for (var k = 0; k < 4; k++) { //Loop through Red, Green, Blue and Alpha and calculate the correct color and opacity
                    color[k] = gradient[prevKey][k] - ((gradient[prevKey][k] - gradient[gradientKey][k]) * gradientPartIndex);
                    if (k < 3) color[k] = Math.round(color[k]);
                }
                chartColors[i] = 'rgba(' + color.toString() + ')';
                break;
            }
        }
    }

    return chartColors;
}

const getRandomColorPalette = function(numberOfColors) {
    var chartColors = [];
    for (var i = 0; i < numberOfColors; i++) {
        chartColors.push(getRandomColor());
    }
    return chartColors;
}

const getRandomColor = function() {
    var letters = '0123456789ABCDEF'.split('');
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export const colorHelper = {
    getColorPalette,
    getRandomColorPalette,
    getRandomColor
};

