<template>
    <div>
        <BarChart ref="distributionBarChart" :chart-data="chartData" :options="barChartOptions" :width="Width" :height="Height" />
    </div>
</template>

<script>

    import { colorHelper } from '@/shared/color-helper';
    import BarChart from '@/components/charts/core/bar-chart';
 
    export default {
        name: 'GainLossBarChart',

        props: {
            TaxRecords: {
                type: Array,
                default: () => [],
            },
            Width: {
                type: Number,
                default: () => 330,
            },
            Height: {
                type: Number,
                default: () => 330,
            },
        },

        /* COMPONENT DATA  */
        data() {
            return {
                barChartOptions: {
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: false
                    },
                    onClick: this.handleDistributionBarChartClick,
                    tooltips: {
                        callbacks: {
                            label: function (tooltipItem, data) {
                                let label = data.labels[tooltipItem.index];
                                let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                                return ' ' + label + ': $' + value;
                            }
                        }
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                // Include a dollar sign in the ticks
                                callback: function (value, index, values) {
                                    return '$' + value;
                                },
                                //fontColor: "black",
                                fontStyle: "900",
                            },
                        }],
                        xAxes: [{
                            ticks: {
                                fontColor: "black",
                                fontStyle: "bold"
                            },

                        }]
                    }
                }
            };
        },

        /* CHILD COMPONENTS  */
        components: {
            BarChart
        },

        /* COMPONENT METHODS  */
        methods: {
            handleDistributionBarChartClick(evt, elements) {
                var chart = this.$refs.distributionBarChart.$data._chart;
                const chartIndex = chart.getElementAtEvent(evt);
                if (chartIndex.length !== 0) {
                    const datasetIndex = chartIndex[0]._datasetIndex;
                    const position = chartIndex[0]._index;
                    const info = {
                        datasetIndex: datasetIndex,
                        valueIndex: position,
                        label: chart.tooltip._data.labels[position],
                        value: chart.tooltip._data.datasets[datasetIndex].data[position]
                    };
                }
            },

            resetChart() {
                //Reset the chart if available
                if (this.$refs.distributionBarChart) {   
                    var chart = this.$refs.distributionBarChart.$data._chart;
                    chart.update(); //Update chart
                }
            },
        },

        /* COMPUTED PROPERTIES */
        computed: {
          
            allAssets() {
                //Retrieve a list of distinct assets with transactions
                if (this.TaxRecords.length > 0) {
                    var assets = [...new Set(this.TaxRecords.map(a => a.assetName).sort())];
                    return assets;
                }
                else
                    return [];
            },

            chartData() {
                var chartData = [];
                let dataArray = [];
                let labelArray = [];
                let colorArray = [];
                for (var x = 0; x < this.allAssets.length; x++) {
                    var asset = this.allAssets[x];
                    var total = this.TaxRecords.filter(x => x.assetName == asset).reduce((n, { gain }) => n + parseFloat(gain), 0);
                    labelArray.push([asset]);
                    dataArray.push(total.toFixed(2));
                }

                colorArray = colorHelper.getColorPalette("happy", this.allAssets.length);
                //colorArray = colorHelper.getRandomColorPalette(this.allAssets.length);

                chartData = {
                    labels: labelArray,
                    datasets: [
                        {
                            backgroundColor: colorArray,
                            data: dataArray
                        }
                    ]
                };

                return chartData;
            }
        }
    };
</script>